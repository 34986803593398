onDomReady(() => {
   /**
    * This ended up being a little confusing because of the CSS animations and
    * the fact we want to use very basic HTML so the dozuki guys can edit the
    * links.
    *
    * The basic HTML structure is:
    * <mainHeader>
    *  <mainNav1>
    *  <mainNav2>
    * </mainHeader>
    * <subNavHeader>
    *   <subNav1>
    *   <subNav2>
    * </subNavHeaader>
    *
    * Clicking one of the main headers will show the subNav associated with
    * it.
    *
    * Because we want to make things mobile friendly, the desired layout
    * changes a little.
    * <mainHeader>
    *  <mainNav1>
    *  <subNav1>
    *  <mainNav2>
    *  <subNav2>
    * </mainHeader>
    *
    * We end up copying the HTML stucture in first example to make the second
    * example so the dozuki guys don't have to worry about editing the same
    * info in 2 spots. It ends up looking like:
    *
    * <mainHeader>
    *  <mainNav1>
    *  <subNav1>
    *  <mainNav2>
    *  <subNav2>
    * </mainHeader>
    * <subNavHeader>
    *   <subNav1>
    *   <subNav2>
    * </subNavHeader>
    *
    * Which using CSS we can make it look like & animate as we desire; sliding
    * in and out the subNav divs in the mobile & standard view & keep the
    * visible state toggled.
    *
    * Note: The animations will be a little weird if you change between mobile
    * and desktop view but the state (subNav is visible or not) should be the
    * same.
    */
   when($('dozukiCustomHeaderMainContainer'), el => {
      // Grab all the main nav buttons that will show a sub nav
      let headerSubNavToggles = $$('.dozukiCustomHeaderSubNavToggle');
      let getClassName = function (linkText) {
         return linkText.toLowerCase() + 'DozukiSubnav';
      };

      headerSubNavToggles.each(link => {
         // Not all the links have subnav info. Act like a normal link if they
         // don't.
         let targetClass = getClassName(link.get('text'));
         let targetSubnavs = $$('.' + targetClass);

         if (!targetSubnavs) {
            return true;
         }

         let targetSubnav = targetSubnavs.first().clone();
         targetSubnav.inject(link, 'after');

         link.addEvent('click', function (ev) {
            ev.stop();

            // Clicking a button could hide the subNav div too. Figure out if
            // they're showing or hiding the subNavigation.
            let subNavVisible = false;
            headerSubNavToggles.each(el => {
               if (el == link) {
                  el.toggleClass('selected');
                  subNavVisible = el.hasClass('selected');
               } else {
                  el.removeClass('selected');
               }
            });

            // The div we use to handle desktop animations.
            let subNavContainer = $('dozukiCustomHeaderSubNavContainer');
            let subNavContainerHeight = 0;

            let subNavClass = getClassName(this.get('text'));

            // Go through the desktop subnav rows.
            subNavContainer.getElements('.dozukiCustomHeaderSubNavRow').each(row => {
               // Find the one we want to show.
               if (row.hasClass(subNavClass)) {
                  // Remove "hidden" and figure out the height to animate.
                  row.removeClass('hidden');
                  subNavContainerHeight = row
                     .getElement('.dozukiCustomHeaderSubNavContent')
                     .getStyle('height')
                     .toInt();
               } else {
                  // Otherwise hide the subnav.
                  row.addClass('hidden');
               }
            });

            // Check if we're showing or hiding the subNavigation.
            if (subNavVisible) {
               // If showing, set the container height to show animation.
               // On mobile view, this will come back as NaN.
               if (subNavContainerHeight) {
                  subNavContainer.setStyle('height', subNavContainerHeight);
               } else {
                  // Not sure why auto doesn't work here but it should be 60.
                  subNavContainer.setStyle('height', 60);
               }
            } else {
               subNavContainer.setStyle('height', 0);
            }

            // Go through the mobile animations.
            let mainNavContainer = $('dozukiCustomHeaderMainNavContainer');
            mainNavContainer.getElements('.dozukiCustomHeaderSubNavRow').each(row => {
               if (row.hasClass(subNavClass)) {
                  if (subNavVisible) {
                     // Copied over from the desktop subnav logic. It's not
                     // needed here for mobile b/c they don't share the same
                     // container but we still need to remove it here for the
                     // subnav info to be shown.
                     row.removeClass('hidden');
                     let targetHeight = row
                        .getElement('.dozukiCustomHeaderSubNavContent')
                        .getStyle('height')
                        .toInt();
                     if (targetHeight) {
                        row.setStyle('height', targetHeight);
                     } else {
                        row.setStyle('height', 'auto');
                     }
                  } else {
                     row.setStyle('height', 0);
                  }
               } else {
                  row.setStyle('height', 0);
               }
            });
         });
      });
   });
});
